import * as React from "react";
import { StarsDisplay } from "../../RatingsAndReviews/AverageRating/StarsDisplay";
import { AverageRatingText, RatingContainer } from "./Rating.styled";

type RatingProps = {
  averageRating: number;
  starsGap?: number;
};
const Rating = ({ averageRating, starsGap = 2 }: RatingProps) => {
  return averageRating > 0 ? (
    <RatingContainer>
      <StarsDisplay rating={averageRating} size={"xs"} gap={starsGap} />
      <AverageRatingText>{averageRating}/5</AverageRatingText>
    </RatingContainer>
  ) : null;
};

export { Rating };

import type { Dispatch } from "react";
import { getHistoryStateValue, replaceHistoryState } from "../../utils/History";
import { PRODUCT_LIST_ID } from "../ProductList/constants";
import {
  ACTION_QUERY_PARAMETER,
  CATEGORY_PATH_QUERY_PARAMETER,
  FACET_CATEGORY_ID,
  QUERY_PARAMETER_ACTIONS,
  SEARCH_ROOT_ID,
  SET_SEARCH_PARAMETERS,
  SORT_QUERY_PARAMETER,
} from "./Constants";
import type { Action, State } from "./SearchState";
import {
  isFacetParam,
  toEntitySortingParameterArray,
  toSelectedFilters,
} from "./SearchStateUrlHelper";
import { parseCategoryPath } from "./SearchStateFilterHelper";

export const saveCurrentScrollPosition = (currentPosition: number): void => {
  replaceHistoryState({ scrollPosition: currentPosition });
};

export const preventBrowserScrollingRestoration = (): void => {
  window.history.scrollRestoration = "manual";
};

export const scrollToPreviousPosition = (): void => {
  const scrollPosition = getHistoryStateValue("scrollPosition");
  if (scrollPosition !== undefined) {
    window.scrollTo({
      top: scrollPosition,
    });
  }
};

export const didUpdateSearchResult = (
  state: State,
  previousState?: State
): boolean =>
  previousState?.isFetchingNewSearchResult === true &&
  state.isFetchingNewSearchResult === false;

export const getScrollToPosition = (): number => {
  const productListElement = document.getElementById(PRODUCT_LIST_ID);
  if (productListElement === null) {
    return 0; // Fallback
  }
  const { bottom } = productListElement.getBoundingClientRect();
  // How much to scroll for products to be visible
  return bottom + window.pageYOffset - window.innerHeight;
};

export const shouldScrollToTopAfterFetchingNewData = (): boolean => {
  const productListElement = document.getElementById(PRODUCT_LIST_ID);
  if (productListElement === null) {
    return false;
  }
  const { bottom, height } = productListElement.getBoundingClientRect();
  const isProductListBiggerThanWindow = height > window.innerHeight;
  const isProductListBottomAboveWindowTop = bottom < 0;
  const isProductListBottomAboveWindowBottom = bottom - window.innerHeight < 0;
  return (
    (isProductListBiggerThanWindow && isProductListBottomAboveWindowBottom) ||
    isProductListBottomAboveWindowTop
  );
};

type ScrollToElementProps = {
  querySelector: string;
  duration?: number;
};

export const scrollToElement = ({
  querySelector,
  duration = 1000,
}: ScrollToElementProps): Promise<void> =>
  new Promise((resolve, reject) => {
    const element = document.querySelector(querySelector);
    if (element === null) {
      return reject();
    }
    const scrollTop = element.getBoundingClientRect().top + window.scrollY;
    void $([document.documentElement, document.body])
      .animate({ scrollTop }, duration)
      .promise()
      .done(() => resolve());
  });

const setCampaignCategoryAndFiltersFromLink = (
  link: HTMLAnchorElement,
  dispatch: Dispatch<Action>
) => {
  const url = new URL(link.href);
  const _categoryPath = url.searchParams.get(CATEGORY_PATH_QUERY_PARAMETER);
  const categoryPath =
    _categoryPath !== null ? parseCategoryPath(_categoryPath) : [];
  const categoryCode = [...categoryPath].pop() ?? null;
  const selectedSort = toEntitySortingParameterArray(
    url.searchParams.get(SORT_QUERY_PARAMETER)
  );
  const allSelectedFilters = toSelectedFilters(url.searchParams);
  const selectedFilters = allSelectedFilters.filter((filter) => {
    return (
      filter.attributeName !== FACET_CATEGORY_ID &&
      filter.attributeName !== CATEGORY_PATH_QUERY_PARAMETER &&
      filter.attributeName !== ACTION_QUERY_PARAMETER &&
      isFacetParam(filter.attributeName)
    );
  });

  const payload = {
    categoryCode,
    categoryPath,
    filters: selectedFilters,
    selectedSort,
  };

  dispatch({
    type: SET_SEARCH_PARAMETERS,
    payload,
  });
};

export const enableAndAttachScrollEventToCampaignActionLinks = (
  campaignId: string,
  dispatch: Dispatch<Action>
): void => {
  [
    ...document.querySelectorAll<HTMLAnchorElement>(
      `[href*="${campaignId}"][href*="${ACTION_QUERY_PARAMETER}=${QUERY_PARAMETER_ACTIONS.ScrollToProductList}"]`
    ),
  ].forEach((link) => {
    link.addEventListener("click", (event) => {
      event.preventDefault();
      void scrollToElement({ querySelector: `#${SEARCH_ROOT_ID}` }).then(() =>
        setCampaignCategoryAndFiltersFromLink(link, dispatch)
      );
    });
  });
};

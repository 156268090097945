import { isNullOrUndefined } from "@xxl/common-utils";
import type { USPData } from "@xxl/product-search-api";
import { useStateValue } from "cotton-box-react";
import { useSessionSource } from "../../contexts/Session";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import type {
  UspClassification,
  UspItem,
} from "../../utils/ProductAttributes/attributes-helper";
import {
  getClassificationsFromNewUsps,
  getClassificationsFromUsps,
} from "../../utils/ProductAttributes/attributes-helper";
import { ProductSpecification, ProductSpecifications } from "./Product.styled";

type Props = {
  limit?: number;
  usps?: UspItem[] | USPData[];
  selectedColumnsNumber: number;
};

export const ProductClasifications = ({
  limit,
  usps,
  selectedColumnsNumber,
}: Props) => {
  const isLoggedIn = useStateValue(useSessionSource);
  const { t } = useTranslations();

  const specifications: UspClassification[] = (
    isNullOrUndefined(usps) || usps.length === 0
      ? []
      : Array.isArray((usps as UspItem[])[0].values)
        ? getClassificationsFromUsps(usps as UspItem[], isLoggedIn, t)
        : getClassificationsFromNewUsps(usps as USPData[], t)
  ).slice(0, limit);

  if (specifications.length === 0) return null;

  return (
    <ProductSpecifications columnAmount={selectedColumnsNumber}>
      {specifications.map(({ name, value, id }, index) => (
        <ProductSpecification
          columnAmount={selectedColumnsNumber}
          key={`${id}-${index}`}
        >
          <span>
            <span>{`${name}: `}</span>
            <span>{value}</span>
          </span>
        </ProductSpecification>
      ))}
    </ProductSpecifications>
  );
};

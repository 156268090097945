import { BadgeType as BADGE_TYPE } from "@xxl/pim-api";
import type {
  BadgeType,
  MultiChannelAvailability,
} from "../../utils/data-types";
import { ChannelTag } from "./ChannelTag/ChannelTag";
import { RibbonsWrapper } from "./Product.styled";
import { AutomatedRibbon } from "./ProductBadge/AutomatedRibbon";

type Props = {
  badges: BadgeType[];
  multiChannelAvailability: MultiChannelAvailability[];
  isBundle?: boolean;
};

export const ProductRibbons = ({
  badges,
  multiChannelAvailability,
  isBundle,
}: Props) => {
  const showChannelTag =
    multiChannelAvailability.length === 1 &&
    !badges.includes(BADGE_TYPE.PRESALE as BadgeType);
  const badgesLimit = showChannelTag ? 0 : 1;
  const showRibbons = showChannelTag || badges.length > 0;

  if (!showRibbons) {
    return null;
  }

  return (
    <RibbonsWrapper>
      {badges.map((badge, index) =>
        index <= badgesLimit && isBundle === false && badge !== "ONLINE" ? (
          <AutomatedRibbon badgeType={badge} key={badge} />
        ) : null
      )}
      {showChannelTag ? (
        <ChannelTag
          multichannelAvailability={multiChannelAvailability}
          isPDPImage={false}
        />
      ) : null}
    </RibbonsWrapper>
  );
};

import type {
  EntitySortingParameter,
  RangeFacetSelectedParameter,
} from "@xxl/search-api";
import {
  FacetTypeEnum,
  EntitySortingParameterCustomNameEnum,
  EntitySortingParameterTypeEnum,
} from "@xxl/search-api";
import type { HistoryState } from "../../utils/History";
import { pushHistoryState, replaceHistoryState } from "../../utils/History";
import { isDistinctFacetParameter, UrlPath } from "./UrlPath";
import {
  ACTION_QUERY_PARAMETER,
  CATEGORY_PATH_QUERY_PARAMETER,
  FACET_CATEGORY_ID,
  FORCE_SHOW_ALL_OF_CAMPAIGN_QUERY_PARAMETER,
  GCLID_QUERY_PARAM_PREFIX,
  GTM_QUERY_PARAM_PREFIX,
  INITIAL_PAGE_NUMBER,
  PAGE_QUERY_PARAMETER,
  PRICE_VALUE_FACET_ATTRIBUTE_NAME,
  QUERY_PARAMETERS,
  SEARCH_QUERY_PARAMETER,
  SORT_QUERY_PARAMETER,
  SYMPLIFY_PREVIEW_MODE_PREFIX,
  SYMPLIFY_VARIANT_ID_PREFIX,
  UTM_QUERY_PARAM_PREFIX,
  WHITELISTED_QUERY_PARAMS,
} from "./Constants";
import type { SelectedSort } from "./SearchState";
import { CATEGORY_LEVEL_3, DUMMY_URL } from "../../constants";
import isEmpty from "lodash/isEmpty";
import type { LongTailData } from "@xxl/frontend-api";
import { parseCategoryPath } from "./SearchStateFilterHelper";
import type { Filter } from "../../utils/data-types";
import { log } from "@xxl/logging-utils";

const FACET_QUERIES_TO_KEEP = [
  CATEGORY_PATH_QUERY_PARAMETER,
  FORCE_SHOW_ALL_OF_CAMPAIGN_QUERY_PARAMETER,
];

const FACET_QUERIES_PREFIX_TO_KEEP_ON_SSR = [
  GCLID_QUERY_PARAM_PREFIX,
  UTM_QUERY_PARAM_PREFIX,
  GTM_QUERY_PARAM_PREFIX,
  ACTION_QUERY_PARAMETER,
  SYMPLIFY_PREVIEW_MODE_PREFIX,
  SYMPLIFY_VARIANT_ID_PREFIX,
];

export const forceSolrAsProviderRegex = /^\d{7}([+,\s]\d{7})*$/;

export function toEntitySortingParameterArray(
  param: string | null
): EntitySortingParameter[] {
  if (param === null || param.length === 0) {
    return [];
  }
  const castedSortParam = param as EntitySortingParameterCustomNameEnum;
  if (
    Object.values(EntitySortingParameterCustomNameEnum).includes(
      castedSortParam
    ) === false
  ) {
    return [];
  }
  return [
    {
      type: EntitySortingParameterTypeEnum.custom,
      customName: castedSortParam,
    },
  ];
}

function updatePageQueryParam(page: number, url: URL): void {
  if (page > INITIAL_PAGE_NUMBER) {
    url.searchParams.set(PAGE_QUERY_PARAMETER, page.toString());
  } else {
    url.searchParams.delete(PAGE_QUERY_PARAMETER);
  }
}
export type SearchStateUrlData = {
  categoryPath: string[];
  selectedSort: EntitySortingParameter[];
  query: string;
  page: number;
  forceSolrAsProvider: boolean;
  selectedCategoryCode: string;
  selectedFilters: Filter[];
  forceShowAll: boolean;
};
export type QueryParameterActions = string[];

export function isFacetParam(urlParam: string): boolean {
  return !QUERY_PARAMETERS.includes(urlParam);
}

const isPriceValueFacet = (attributeName: string) =>
  attributeName === PRICE_VALUE_FACET_ATTRIBUTE_NAME;

const getRangeValue = (value: string) => {
  const parsedValue: RangeFacetSelectedParameter = JSON.parse(
    value
  ) as RangeFacetSelectedParameter;
  const { max, min } = parsedValue;
  if (max === undefined || min === undefined) {
    throw new Error("Missing required values in range filter");
  }

  return parsedValue;
};

const getSelectedFilterValue = (
  attributeName: string,
  value: string
): Filter => {
  const filter = {
    attributeName,
    type: FacetTypeEnum.distinct,
    selected: [value],
  };
  if (isPriceValueFacet(attributeName)) {
    const rangeValue = getRangeValue(value);
    return {
      ...filter,
      type: FacetTypeEnum.range,
      selected: rangeValue,
    };
  }
  return filter;
};

export function toSelectedFilters(searchParams: URLSearchParams): Filter[] {
  const filters: Filter[] = [];

  Array.from(searchParams.entries())
    .filter(([key]) => isFacetParam(key))
    .forEach(([attributeName, value]) => {
      const matchingFilter = filters.find(
        (f) => f.attributeName === attributeName
      );
      if (
        matchingFilter !== undefined &&
        isDistinctFacetParameter(matchingFilter)
      ) {
        matchingFilter.selected?.push(value);
        return;
      }
      try {
        const filter = getSelectedFilterValue(attributeName, value);
        filters.push(filter);
      } catch (_) {
        log.warn(
          `Couldn't add selected filter for ${attributeName} with ${value}`
        );
      }
    });

  return filters;
}

export const parseSearchParams = (
  searchParams: URLSearchParams
): SearchStateUrlData => {
  const selectedSort = toEntitySortingParameterArray(
    searchParams.get(SORT_QUERY_PARAMETER)
  );
  const query = searchParams.get(SEARCH_QUERY_PARAMETER) ?? "";
  const page = searchParams.get(PAGE_QUERY_PARAMETER) ?? "";
  const _categoryPath = searchParams.get(CATEGORY_PATH_QUERY_PARAMETER);
  const categoryPath =
    _categoryPath !== null ? parseCategoryPath(_categoryPath) : [];
  const selectedCategoryCode = [...categoryPath].pop() ?? "";
  const forceShowAll =
    searchParams.get(FORCE_SHOW_ALL_OF_CAMPAIGN_QUERY_PARAMETER) === "true";
  const forceSolrAsProvider = forceSolrAsProviderRegex.test(query);
  const allSelectedFilters = toSelectedFilters(searchParams);
  const NON_FILTER_PARAMETERS = [
    FACET_CATEGORY_ID,
    FORCE_SHOW_ALL_OF_CAMPAIGN_QUERY_PARAMETER,
    CATEGORY_PATH_QUERY_PARAMETER,
  ];
  const selectedFilters = allSelectedFilters
    .filter(
      ({ attributeName }): boolean =>
        !NON_FILTER_PARAMETERS.includes(attributeName)
    )
    .filter((filter): boolean => isFacetParam(filter.attributeName));
  return {
    forceShowAll,
    categoryPath,
    selectedSort,
    query,
    page: isEmpty(page) ? INITIAL_PAGE_NUMBER : parseInt(page),
    forceSolrAsProvider,
    selectedCategoryCode,
    selectedFilters,
  };
};

export const getQueryParameterActions = (): QueryParameterActions => {
  const url = new URL(
    typeof window !== "undefined" ? window.location.href : DUMMY_URL
  );
  return url.searchParams.getAll(ACTION_QUERY_PARAMETER);
};

export const removeQueryParameters = (
  queryParams: string[],
  url: URL
): void => {
  queryParams.forEach((queryParam) => url.searchParams.delete(queryParam));
};

const isFacetQueryToKeepInSSR = (param: string) =>
  typeof window === "undefined" &&
  FACET_QUERIES_PREFIX_TO_KEEP_ON_SSR.some((prefix) =>
    param.startsWith(prefix)
  );

export function updateSelectedFiltersURL(
  selectedFilters: Filter[],
  url: URL,
  longTailFacets?: LongTailData[],
  longTailPattern?: string
): void {
  Array.from(url.searchParams.keys())
    .filter(
      (param) =>
        isFacetParam(param) &&
        !FACET_QUERIES_TO_KEEP.includes(param) &&
        !isFacetQueryToKeepInSSR(param)
    )
    .forEach((filter) => url.searchParams.delete(filter));

  const urlPath = new UrlPath(
    url,
    selectedFilters,
    longTailFacets,
    longTailPattern
  );
  urlPath.updateFilters();
}

export const getUpdatedPageUrlAfterSearch = ({
  selectedFilters,
  longTailFacets,
  longTailPattern,
  page,
  url,
  categoryLevel = CATEGORY_LEVEL_3,
}: {
  longTailFacets?: LongTailData[];
  longTailPattern?: string;
  page: number;
  selectedFilters: Filter[];
  url: URL;
  categoryLevel?: number;
}): URL => {
  updatePageQueryParam(page, url);
  updateSelectedFiltersURL(
    [...selectedFilters],
    url,
    //for categories with category level < 3 the URL should receive all selectedFilters as query parameters, therefore longTailFacets are set to empty
    categoryLevel < CATEGORY_LEVEL_3 ? [] : longTailFacets,
    longTailPattern
  );
  return url;
};

const searchParamsContainsWhitelistedParams = (): boolean => {
  if (typeof window !== "undefined") {
    const searchParams = window.location.search;
    return WHITELISTED_QUERY_PARAMS.some((param) =>
      searchParams.includes(param)
    );
  }

  return false;
};

export const pushOrUpdatePageUrl = ({
  categoryId,
  selectedFilters,
  selectedSort,
  longTailFacets,
  longTailPattern,
  page,
  shouldUsePushNewUrl = false,
  categoryLevel,
}: {
  categoryId?: string;
  longTailFacets?: LongTailData[];
  longTailPattern?: string;
  page: number;
  selectedFilters: Filter[];
  selectedSort: SelectedSort;
  shouldUsePushNewUrl?: boolean;
  categoryLevel?: number;
}): void => {
  const url = getUpdatedPageUrlAfterSearch({
    url: new URL(window.location.href),
    page,
    selectedFilters,
    longTailFacets,
    longTailPattern,
    categoryLevel,
  });

  if (searchParamsContainsWhitelistedParams()) {
    return;
  }

  removeQueryParameters([ACTION_QUERY_PARAMETER], url);

  const replaceOrPush = shouldUsePushNewUrl
    ? pushHistoryState
    : replaceHistoryState;
  const historyState: HistoryState = {
    categoryId,
    page,
    selectedFilters,
    selectedSort,
  };
  replaceOrPush(historyState, "", url.href);
};

export const removeActionParamFromUrl = (): void => {
  const url = new URL(window.location.href);
  url.searchParams.delete(ACTION_QUERY_PARAMETER);
  replaceHistoryState({}, "", url.href);
};
